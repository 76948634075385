import { Button, GroupWidgetWrapper } from "../../composables";
import { Layout } from "../Layout/Layout";
import orderfood from "../../../assets/Svg/orderfood.svg";
import readytocook from "../../../assets/Svg/readytocook.svg";
import { VendorCard } from "../_component";
import { useNavigate } from "react-router-dom";
import { useFetchFoodVendors, useFetchVendorMeals } from "../../../utils/api";
import { VendorListSkeleton } from "components/composables/Skeletons/vendorlIst";
import { MealItemsEntity } from "types/global.interface";
import { useState, useEffect } from 'react';
// import { MealCardSkeleton } from "components/composables/Skeletons/MealCard";
// import { useDispatch } from 'react-redux';
// import axios from "utils/useAxios";

export function Home() {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const [restaurantId, setRestaurantId] = useState('')
  const { data, isLoading } = useFetchFoodVendors('restaurant');
  const { data: freshFarmProduce } = useFetchFoodVendors('fresh_food');

  // const { data: SMeals, isLoading: isSMealLoading } = useGetMealSuggestion();
  // console.log(SMeals)
  const restaurantArray = ['64c7eec042d81c94f49f7f9f', '64be2c7ba6f8f7775a8d9ac9', '647cbf9d815ca33c32ee779e', '64dba8b6d3938a0a6c587928', '650c5a8ce1a97b3fcd0a853e']

  useEffect(() => {
    if (!restaurantId) {
      const id = restaurantArray[Math.floor(Math.random() * restaurantArray.length)];
      setRestaurantId(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId]);


  const { data: Meals, isLoading: isMealLoading } = useFetchVendorMeals(restaurantId);
  const [randomMeals, setRandomMeals] = useState<MealItemsEntity[]>([])
  console.log(randomMeals);
  console.log(isMealLoading);

  useEffect(() => {
    if (Meals && Meals?.length > 0) {
      let randomMealArray: MealItemsEntity[] = [];
      while (randomMealArray.length < 4) {
        const randomIndex = Math.floor(Math.random() * Meals.length);
        if (!randomMealArray.includes(Meals[randomIndex])) {
          randomMealArray.push(
            Meals[randomIndex]
          )
        }
      }

      setRandomMeals(randomMealArray);
      console.log(randomMealArray);

    }

  }, [Meals])


  // async function handleHandPickedSelection(meal: MealItemsEntity) {

  //   dispatch({
  //     type: "UPDATE_HANDPICKED_MEAL",
  //     payload: meal
  //   })
  //   const response = await axios.get(`vendors/${meal.vendor._id}`)

  //   dispatch({
  //     type: "GET_ACTIVE_VENDOR",
  //     payload: response.data.data,
  //   })
  //   navigate(`/customer/vendor/${meal.vendor._id}?action=openMeal`)
  // }

  return (
    <Layout hasTopBar>
      <div className="w-full flex mt-24 flex-col gap-y-4 pb-20 items-center">
        <Button
          onClick={() => {
            navigate("/customer/home/restaurants");
          }}
          className="px-0 h-fit w-full"
        >
          <img src={orderfood} alt="" className="w-full h-full" />
        </Button>
        <Button
          onClick={() => {
            navigate("/customer/home/fresh_food_vendors");
          }}
          className="px-0 h-fit w-full"
        >
          <img src={readytocook} alt="" className="w-full h-full" />
        </Button>

        {/* <div className="flex flex-col items-start justify-start w-full gap-y-3">
          <h2 className="font-semibold text-base">Handpicked for you</h2>
          {!isMealLoading ? <div className="grid grid-cols-2 items-center gap-4 w-full ">
            {(randomMeals ?? []).map((meal: any, key: any) => (
              <MealCard meal={meal} key={key} onClick={() => handleHandPickedSelection(meal)} />
            ))}
          </div> :

            <div className="grid grid-cols-2 items-center gap-2 w-full ">
              {[1, 2, 3, 4].map((_, key) => (
                <MealCardSkeleton key={key} />
              ))}
            </div>
          }
        </div> */}

        {isLoading ? (
          <VendorListSkeleton />
        ) : (
          <GroupWidgetWrapper
            header="Featured Restaurants"
            link="/customer/home/restaurants"
          >
            <div className="w-full overflow-x-auto no-scrollbar">
              <div className="min-w-max flex gap-2 ">
                {data?.items?.map((v, key: number) => (
                  <VendorCard className="w-[300px]" key={key} item={v} />
                ))}
              </div>
            </div>
          </GroupWidgetWrapper>
        )}

        <GroupWidgetWrapper
          header="Featured Fresh Food Vendors"
          link="/customer/home/fresh_food_vendors"
        >
          <div className="w-full overflow-x-auto no-scrollbar">
            <div className="min-w-max flex gap-2  ">
              {freshFarmProduce?.items.map((v, key: number) => (
                <VendorCard className="w-[300px]" key={key} item={v} />
              ))}
            </div>
          </div>
        </GroupWidgetWrapper>
      </div>
    </Layout>
  );
}
