import { useNavigate } from "react-router";
import { Add, ArrowLeft } from "iconsax-react";
import ngflag from "assets/Svg/ngflag.svg";
import {
  Location,
  Form,
  FormItem,
  FormField,
  FormControl,
  Input,
  FormMessage,
  FormLabel,
  Button,
  ReactSelect,
} from "../../composables";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useGetUserProfile, useUpdateCustomerProfile } from "utils/api";
import { useSelector } from "react-redux";
import { RootState } from "Redux/reducer";
import { toast } from "react-toastify";
import { errorLogger } from "utils/helper";
import { useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { landmarks } from "utils/AlphaData";
import { User2 } from "lucide-react";
import { useFileUploadHandler } from "utils/fileUploader";
import { FaSpinner } from "react-icons/fa6";
import { yupResolver } from "@hookform/resolvers/yup";
import { vendorUpdateValidationSchema } from "validations";
import { Success } from "assets/icon";

const EditProfile = () => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { ImageUrl, imageLoader, handleFileUpload } = useFileUploadHandler();

  let landMarks: { label: string; value: string }[] = [];
  // eslint-disable-next-line array-callback-return
  landmarks.map((v) => {
    landMarks.push({ label: v.location, value: v.zone });
  });
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [location, setLocation] = useState<string | null>(
    currentUser?.location ?? null
  );
  const client = useQueryClient();
  useGetUserProfile();

  const navigate = useNavigate();
  const form = useForm({
    defaultValues: {
      firstName: currentUser?.fullName.split(" ")[0] ?? "",
      lastName: currentUser?.fullName.split(" ")[1] ?? "",
      email: currentUser?.email ?? "",
      phoneNumber: currentUser?.phoneNumber ?? "",
      businessName: currentUser?.businessName ?? "",
      location: currentUser?.location ?? "",
      address: {
        streetAddress: currentUser?.address?.streetAddress ?? "",
        nearestLandmark: {
          location: currentUser?.address?.nearestLandmark?.location ?? "",
          zone: currentUser?.address?.nearestLandmark?.zone ?? "",
        },
      },
    },
    resolver: yupResolver(vendorUpdateValidationSchema),
  });
  console.log(form.formState.errors);


  const { mutateAsync, isLoading } = useUpdateCustomerProfile();
  const handleSubmit = async (data: any) => {
    const payload = {
      fullName: data.firstName + " " + data.lastName,
      phoneNumber: data.phoneNumber,
      businessName: data.businessName,
      location: data.location.toLowerCase(),
      address: {
        streetAddress:
          data.address.streetAddress ?? currentUser?.address.streetAddress,
        nearestLandmark: {
          location:
            data.address?.nearestLandmark?.label ??
            currentUser?.address.nearestLandmark.location,
          zone:
            data.address?.nearestLandmark?.value ??
            currentUser?.address.nearestLandmark.zone,
        },
      },
    };
    try {
      await mutateAsync(payload, {
        onSuccess: () => {
          toast.success("Profile Updated Successfully", { icon: Success });
          client.refetchQueries("get_user");
          navigate(-1);
        },
      });
    } catch (e: any) {
      errorLogger(e);
    }
  };

  const handleCoverImageChange = async (e: any) => {
    try {
      const file = e.target.files[0];
      await handleFileUpload(file);
    } catch (error) {
      errorLogger(error);
    }
  };

  useEffect(() => {
    if (ImageUrl) {
      const payload = {
        profileImage: {
          url: ImageUrl,
        },
      };
      try {
        mutateAsync(payload, {
          onSuccess: () => {
            toast.success("Profile Image updated successfully", {
              icon: Success,
            });
          },
          onError(error) {
            errorLogger(error);
          },
        });
      } catch (error) {
        errorLogger(error);
      }
    }
  }, [ImageUrl, mutateAsync]);
  useEffect(() => {
    form.setValue("location", location || "");
  }, [form, location]);

  return (
    <div className="p-5">
      <div className="flex items-center gap-3">
        <button
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowLeft size={30} />
        </button>
        <h1 className="font-semibold text-xl">Edit Profile</h1>
      </div>

      {/* Avatar and Rider info */}
      <div className="relative flex flex-col items-center py-10 border-b-[3px] border-b-grey-50">
        {/* Avatar */}
        <div className="relative">
          <div className="w-20 h-20 border grid place-content-center rounded-full overflow-hidden">
            {currentUser?.profileImage ? (
              <img src={currentUser.profileImage.url} alt="profileImage" />
            ) : (
              <div>
                {imageLoader ? (
                  <FaSpinner className="animate-spin" size={30} />
                ) : (
                  <User2 size={40} />
                )}
              </div>
            )}
          </div>
          <input
            type="file"
            name=""
            hidden
            id="upload"
            accept="image/*"
            onChange={(e) => handleCoverImageChange(e)}
          />
          <label
            htmlFor="upload"
            className="absolute bottom-0 right-0 p-0.5 h-6 w-6 items-center justify-center flex rounded-full bg-primary-100 text-white border-2 border-white"
          >
            <Add />
          </label>
        </div>
      </div>

      {/* Profile edit form */}
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => {
            handleSubmit(data);
          })}
          className="mt-5"
        >
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs text-[#999]">
                  First Name
                </FormLabel>
                <FormControl>
                  <Input
                    placeholder="John"
                    type="text"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs text-[#999]">Last Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Champion"
                    type="text"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="email"
            disabled
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs text-[#999]">Email</FormLabel>
                <FormControl>
                  <Input
                    placeholder="example@xyz.com"
                    type="email"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    disabled
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="businessName"

            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs text-[#999]">Business Name</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Abc Foods"
                    type="text"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <FormField
            control={form.control}
            name="phoneNumber"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel className="text-xs text-[#999]">
                  Phone Number
                </FormLabel>
                <FormControl>
                  <div className="relative w-full h-10">
                    <div className="absolute top-2 left-2">
                      <img className="px-3 py-2" src={ngflag} alt="" />
                    </div>

                    <Input
                      placeholder="09083234532"
                      type="tel"
                      className="w-full mt-2 pl-14 pr-5 bg-grey-30 rounded-lg"
                      {...field}
                    />
                  </div>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="mt-5">
            <FormField
              control={form.control}
              name="address.nearestLandmark"
              render={({ field }) => (
                <ReactSelect
                  {...field}
                  defaultValue={
                    currentUser?.address?.nearestLandmark?.zone ?? ""
                  }
                  label="LandMark"
                  placeHolder="Gidan Kwano"
                  options={landMarks}
                />
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="address.streetAddress"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel className="text-xs text-[#999]">Address</FormLabel>
                <FormControl>
                  <Input
                    type="text"
                    className="w-full mt-2 py-2 px-5 bg-grey-30 rounded-lg border-none text-[#0E0E0E]"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {/* location */}
          <FormField
            control={form.control}
            name="location"
            render={({ field }) => (
              <FormItem className="w-full mt-5">
                <FormLabel>Location</FormLabel>
                <FormControl>
                  <Input
                    placeholder="Abuja"
                    readOnly
                    type="text"
                    onFocus={() => {
                      setIsLocationOpen(true);
                    }}
                    className="w-full mt-2 bg-grey-30 rounded-lg capitalize"
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <AnimatePresence initial={false} mode="wait">
            {isLocationOpen && (
              <Location
                closeLocation={() => {
                  setIsLocationOpen((prev) => !prev);
                }}
                selectLocation={(value) => {
                  setLocation(value);
                }}
              />
            )}
          </AnimatePresence>

          <Button
            type="submit"
            className="mt-10 w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
            onClick={() => {
              console.log("test");
            }}
          >
            {isLoading ? (
              <span className="inline-block animate-spin h-4 w-4 rounded-full border-4 border-l-white border-b-white border-t-gray-400 border-r-gray-400"></span>
            ) : (
              "Update Profile"
            )}
          </Button>
        </form>
      </Form>
    </div>
  );
};

export { EditProfile };
