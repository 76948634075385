import { FC } from "react";
import deleteFood from "../../../assets/Svg/deleteFood.svg";
import { Button } from "../../composables";
import { motion } from "framer-motion";

const animate = {
  hidden: {
    scale: 0,
  },
  visible: {
    scale: 1,
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    scale: 0,
    trasition: {
      duration: 0.2,
    },
  },
};

type DeleteProps = {
  handleDelete: () => void;
  closeModal: () => void;
  mealName: string;
};
const DeleteFoodModal: FC<DeleteProps> = ({ handleDelete, closeModal, mealName }) => {
  return (
    <div className="fixed inset-x-0 top-0 bg-[#00000070] flex items-center justify-center h-screen">
      <motion.div
        variants={animate}
        initial="hidden"
        exit={"exit"}
        animate="visible"
        className="py-10 px-16 rounded-xl bg-white flex flex-col items-center"
      >
        <div>
          <img src={deleteFood} alt="" />
        </div>
        <h1 className="mt-5 font-bold text-center">Delete</h1>
        <p className="max-w-[200px] text-center">
          {mealName}
        </p>
        <div className="mt-5 text-center text-xs max-w-[200px]">
          Are you sure you want to delete the listed food
        </div>
        <div className="mt-10 flex flex-col gap-4 w-[200px]">
          <Button
            onClick={handleDelete}
            className="w-full h-12 py-2 px-3 rounded-xl bg-[#FF4444] text-white"
          >
            Delete
          </Button>
          <Button
            onClick={closeModal}
            className="w-full h-12 py-2 px-3 rounded-xl border border-[#7BBE4A] text-[#7BBE4A]"
          >
            Cancel
          </Button>
        </div>
      </motion.div>
    </div>
  );
};

export default DeleteFoodModal;
