import { motion } from "framer-motion";

type LocationProp = {
  closeLocation: () => void;
  selectLocation: (value: string) => void;
  list?: string[];
  label?: string;
};
const predefineLocations = ["Minna", "Lagos", "Ibadan", "Jos", "Ilorin", "Abuja", "Kaduna", "Kano",];

const Location = ({ closeLocation, selectLocation, list, label }: LocationProp) => {
  list = list ?? predefineLocations

  console.log(list);
  const animate = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: "0",
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      y: "100%",
    },
  };
  return (
    <motion.div
      variants={animate}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="absolute sm:max-w-[600px] mx-auto z-10 max-h-[70vh] bottom-0 inset-x-0 py-10 px-5 bg-white border rounded-t-[40px] overflow-y-scroll"
    >
      <div className="absolute inset-x-0 top-3 -translate-x-1/2 left-1/2 w-14 h-1.5 rounded-full bg-grey-30" onClick={closeLocation}></div>
      <h2 className="font-bold mb-3">Pick {label ?? "Location"}</h2>
      {list.map((location, index) => (
        <label
          htmlFor={String(index)}
          key={index}
          onClick={() => { selectLocation(location) }}
          className="my-2 p-3 bg-grey-30 text-sm rounded-lg flex justify-between items-center"
        >
          <p>{location}</p>
          <input
            id={String(index)}
            name="location"
            type="radio"
            value={location.toLowerCase()}
            onChange={() => selectLocation(location)}
            onClick={
              closeLocation
            }
          />
        </label>
      ))}
    </motion.div>
  );
};

export { Location };
