import { FC } from "react";
import { motion } from "framer-motion";
import { Button } from "components/composables";

const animate = {
    hidden: {
        scale: 0,
    },
    visible: {
        scale: 1,
        transition: {
            duration: 0.5,
        },
    },
    exit: {
        scale: 0,
        trasition: {
            duration: 0.2,
        },
    },
};

type ActionProp = {
    handleLogin: () => void;
    closeModal: () => void;
};
const LoginToOrder: FC<ActionProp> = ({ handleLogin, closeModal }) => {
    return (
        <div className="fixed inset-x-0 top-0 bg-[#00000070] flex items-center justify-center h-screen">
            <motion.div
                variants={animate}
                initial="hidden"
                exit={"exit"}
                animate="visible"
                className="p-10 rounded-xl bg-white flex flex-col items-center"
            >
                <div>
                    <svg
                        width="64"
                        height="64"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M12 2L4 5V11C4 16 7 20 12 22C17 20 20 16 20 11V5L12 2Z"
                            fill="#34D399"
                            stroke="#059669"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M9 12H16M16 12L14 10M16 12L14 14"
                            stroke="white"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>

                </div>
                <h1 className="mt-5 font-bold text-center">Login Required</h1>

                <div className="mt-5 text-center text-xs max-w-[200px]">
                    Please proceed to login to finish your ordering process.
                </div>
                <div className="mt-10 flex flex-col gap-4 w-[200px]">
                    <Button
                        onClick={handleLogin}
                        className="w-full h-12 py-2 px-3 rounded-xl bg-primary-100 text-white"
                    >
                        Login
                    </Button>
                    <Button
                        onClick={closeModal}
                        className="w-full h-12 py-2 px-3 rounded-xl border border-red-500 text-red-500"
                    >
                        Cancel
                    </Button>
                </div>
            </motion.div>
        </div>
    );
};

export default LoginToOrder;
